"use client"
import { useEffect, useState, createContext } from "react"
import { add, init, track, setUserId } from "@amplitude/analytics-browser"
import { sessionReplayPlugin } from "@amplitude/plugin-session-replay-browser"
import { useSessionContext } from "supertokens-auth-react/recipe/session"

const AMPLITUDE_API_KEY = process.env.NEXT_PUBLIC_AMPLITUDE_API_KEY

export const AmplitudeContext = createContext({})

const AmplitudeContextProvider = ({ children }) => {
    const session = useSessionContext()
    const [isAmplitudeInitialized, setIsAmplitudeInitialized] = useState(false)

    useEffect(() => {
        const sessionReplayTracking = sessionReplayPlugin()
        add(sessionReplayTracking)
        init(AMPLITUDE_API_KEY, {
            defaultTracking: true, // needed to remove console warning, but autocapture supersedes this
            autocapture: {
                elementInteractions: true
            }
        })
        setIsAmplitudeInitialized(true)
    }, [])

    useEffect(() => {
        if (isAmplitudeInitialized && session.loading === false) {
            if (session.doesSessionExist && session.accessTokenPayload?.username) {
                setUserId(session.accessTokenPayload.username)
            }
        }
    }, [isAmplitudeInitialized, session])

    const trackAmplitudeEvent = (eventName, eventProperties) => {
        track(eventName, eventProperties)
    }

    const value = { trackAmplitudeEvent }

    return <AmplitudeContext.Provider value={value}>{children}</AmplitudeContext.Provider>
}

export default AmplitudeContextProvider
