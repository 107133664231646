"use client"
import React, { useEffect } from "react"
import { store } from "@utils/store"
import { fetchRest } from "@lib/fetchRest"
import { SWRConfig } from "swr"

if (process.env.ENV !== "PROD") {
    import("../utils/devFetchInterceptor")
}
const swrConfig = {
    fetcher: (url: string) => fetchRest(url)
}
const klaviyoUrl = process.env.NODE_ENV === "production" ? `https://static.klaviyo.com/onsite/js/klaviyo.js?company_id=${process.env.NEXT_PUBLIC_KLAVIYO_SITE_ID}` : ""

export const Providers: React.FC<React.PropsWithChildren<{ className: string }>> = ({ children, className }) => {
    const scriptTargetRef = React.useRef<HTMLDivElement>(null)

    useEffect(() => {
        const handler = () => store.set("isMobile", !!mediaQuery.matches)

        const mediaQuery = window.matchMedia("(max-width: 992px)")

        if (mediaQuery.addEventListener) {
            mediaQuery.addEventListener("change", handler)
        } else {
            mediaQuery.addListener(handler)
        }
        handler()
        return () => {
            if (mediaQuery.removeEventListener) {
                mediaQuery.removeEventListener("change", handler)
            } else {
                mediaQuery.removeListener(handler)
            }
        }
    }, [])
    return (
        <body className={className}>
            <SWRConfig value={swrConfig}>{children}</SWRConfig>
            <div ref={scriptTargetRef} />

            {klaviyoUrl && (
                <>
                    <script
                        dangerouslySetInnerHTML={{
                            __html: '!function(){if(!window.klaviyo){window._klOnsite=window._klOnsite||[];try{window.klaviyo=new Proxy({},{get:function(n,i){return"push"===i?function(){var n;(n=window._klOnsite).push.apply(n,arguments)}:function(){for(var n=arguments.length,o=new Array(n),w=0;w<n;w++)o[w]=arguments[w];var t="function"==typeof o[o.length-1]?o.pop():void 0,e=new Promise((function(n){window._klOnsite.push([i].concat(o,[function(i){t&&t(i),n(i)}]))}));return e}}})}catch(n){window.klaviyo=window.klaviyo||[],window.klaviyo.push=function(){var n;(n=window._klOnsite).push.apply(n,arguments)}}}}();'
                        }}
                    />
                    <script src={klaviyoUrl} async />
                </>
            )}
        </body>
    )
}
