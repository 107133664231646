import { Drawer, notification, Popover } from "antd"
import { UserOutlined } from "@ant-design/icons"
import css from "@css/modules/Layout.module.scss"
import Link from "next/link"
import React, { useEffect, useMemo, useState } from "react"
import { usePathname } from "next/navigation"
import cn from "classnames"
import { Hamburger } from "@css/icons/SVG"
import { LoginModal, openModal } from "./LoginModal"
import { store } from "@utils/store"

import Image from "next/image"
import logo2x from "@public/images/logo@2x.png"
import { signOut } from "supertokens-auth-react/recipe/session"
import { Favorites } from "./Favorites"
import { Insta } from "@css/icons/Insta"
import { Facebook } from "@css/icons/Facebook"
import { X } from "@css/icons/X"
import { Mail } from "@css/icons/Mail"
import { mutate } from "swr"
import { appInfo } from "config/appInfo"
import { useUser } from "@lib/api/user"

const menuList = [
    {
        title: "View listings",
        link: "/listings"
    },
    {
        title: "Hottest deals",
        link: "/hottest-deals"
    },
    {
        title: "Resorts",
        link: "/resorts"
    },
    /*    {
        title: "FAQs",
        link: "/faq"
    },*/
    /*    {
        title: "Sell a contract",
        link: "/sell"
    },*/
    {
        title: "Blog",
        link: "/blog"
    },
    {
        title: "Points guide",
        link: "/points-guide"
    }
    /*
    {
        title: "How it works",
        link: "/how-it-works"
    },
    {
        title: "Take a demo",
        link: "/demo"
    }*/
]

export default function HeaderComponent() {
    const pathname = usePathname()
    const { user } = useUser()
    const userName = user?.first_name || user?.emails?.[0] || null
    const [sticky, setSticky] = useState(false)

    useEffect(() => {
        store.set(store.k.headerReady, true)
        const onScroll = () => {
            setSticky(window.scrollY > 100)
        }
        window.addEventListener("scroll", onScroll)
        return () => {
            window.removeEventListener("scroll", onScroll)
            store.set(store.k.headerReady, false)
        }
    }, [])

    const classNames = cn(css.header, {
        [css.transparent]: pathname === "/",
        [css.sticky]: sticky
    })
    const [visible, setVisible] = useState(false)
    const showDrawer = () => {
        setVisible(true)
    }
    const onClose = () => {
        setVisible(false)
    }
    const logout = async () => {
        setVisible(false)
        await signOut()
        mutate(`${appInfo.apiDomain}/api/user`, null)
        notification.open({
            message: "You have been successfully logged out.",
            type: "success"
        })
    }
    const menuItems = useMemo(
        () =>
            menuList.map((i, k) => {
                let active = false
                if (i.link === pathname) {
                    active = true
                }
                return (
                    <li key={k} className={css.headerMenuItem} data-active={active}>
                        <Link href={i.link} className={css.headerMenuItemLink} onClick={onClose}>
                            {i.title}
                        </Link>
                    </li>
                )
            }),
        [pathname]
    )
    const menu = <ul className={css.headerMenu}>{menuItems}</ul>
    const loggedSubmenu = (
        <ul className={css.subMenu}>
            <li key="settings" className={css.headerMenuItem} onClick={onClose}>
                <Link href="/user" className={css.headerMenuItemLink}>
                    Settings
                </Link>
            </li>
            <li className={css.headerMenuItem} key="logout" onClick={logout}>
                Logout ({userName})
            </li>
        </ul>
    )
    const socialLinks = (
        <div className={css.socialContainer}>
            <a className={css.mail} href="mailto:help@dvcforless.com">
                <Mail />
                help@dvcforless.com
            </a>
            <a href="https://www.instagram.com/dvcforless" target="_blank">
                <Insta /> instagram
            </a>
            <a href="https://www.facebook.com/dvcforless" target="_blank">
                <Facebook /> facebook
            </a>
            <a href="https://twitter.com/dvcforless" target="_blank">
                <X /> X / twitter
            </a>
        </div>
    )
    const signalButtons = (
        <>
            <button id="signup-free-btn" className={css.signUpButton} onClick={() => openModal(null, true)}>
                Sign Up <span className={css.freeText}> - It’s Free</span>
            </button>
            <button aria-label="sign in" onClick={() => openModal()} className={css.signInButton}>
                <UserOutlined />
            </button>
        </>
    )
    return (
        <>
            <header className={classNames}>
                <div className={css.socialLinks}>{socialLinks}</div>
                <nav className={css.headerNav}>
                    <Link href="/">
                        <Image src={logo2x} alt="DVC logo" width={70} height={49} className={css.logo} />
                    </Link>
                    <div id="headerInject" className={css.headerInject} />
                    <div className={css.headerMobile}>
                        <Favorites />
                        {!userName && signalButtons}
                        <button aria-label="Hamburger menu" onClick={showDrawer} children={<Hamburger />} className={css.headerMenuToggleBtn} />
                        <Drawer className={css.asideMenuDrawer} placement="right" closable={false} onClose={onClose} open={visible} width={275}>
                            {userName ? (
                                loggedSubmenu
                            ) : (
                                <ul className={css.subMenu}>
                                    <li
                                        onClick={() => {
                                            onClose()
                                            openModal()
                                        }}
                                        className={css.headerMenuItem}>
                                        login
                                    </li>
                                </ul>
                            )}
                            {menu}
                            {socialLinks}
                        </Drawer>
                    </div>
                    <div className={css.headerDesktop}>
                        {menu}
                        <Favorites />
                        {!userName && signalButtons}
                        {userName && (
                            <Popover placement="bottom" content={loggedSubmenu} trigger="hover" arrowPointAtCenter overlayClassName={css.userPopover}>
                                <div className={css.userIcon}>
                                    <UserOutlined /> {userName}
                                </div>
                            </Popover>
                        )}
                    </div>
                </nav>
            </header>
            <LoginModal />
        </>
    )
}
