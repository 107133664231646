"use client"
import { useEffect, useState, createContext } from "react"
import { useSessionContext } from "supertokens-auth-react/recipe/session"

import posthog from "posthog-js"
import { PostHogProvider as PHProvider } from "posthog-js/react"

//TODO: https://github.com/vercel/next.js/issues/51581
//import PostHogPageView from "../PostHogPageView"
import dynamic from "next/dynamic"
const PostHogPageView = dynamic(() => import("../PostHogPageView"), {
    ssr: false
})

/// <reference types="posthog-js" />

declare global {
    interface Window {
        posthog: typeof posthog
    }
}

export function PostHogProvider({ children }: { children: React.ReactNode }) {
    const session = useSessionContext()
    const [isPostHogInitialized, setIsPostHogInitialized] = useState(false)

    useEffect(() => {
        posthog.init(process.env.NEXT_PUBLIC_POSTHOG_KEY, {
            api_host: process.env.NEXT_PUBLIC_POSTHOG_HOST,
            ui_host: "https://us.posthog.com",
            loaded: (posthog) => {
                // if (process.env.NODE_ENV === "development") posthog.debug()
                window.posthog = posthog // Make PostHog available globally for debugging
            },
            person_profiles: "always", // or 'identified_only' to omit profiles for anonymous users
            capture_pageview: false, // Disable automatic pageview capture, as we capture manually
            capture_pageleave: true // Enable pageleave capture -- TODO: do we care?
        })
        setIsPostHogInitialized(true)
    }, [])

    useEffect(() => {
        if (isPostHogInitialized && session.loading === false) {
            if (session.doesSessionExist && session.accessTokenPayload?.username) {
                posthog.identify(session.accessTokenPayload.username)
            }
        }
    }, [isPostHogInitialized, session])

    return (
        <PHProvider client={posthog}>
            <PostHogPageView />
            {children}
        </PHProvider>
    )
}
